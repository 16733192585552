import './Map.scss';
import pointer from '../../imgs/pointer.png';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, memo, useState } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { centerChange } from '../../redux/actions';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const Map = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDczz8zwETI6vcJDMk5g2vELbhMvviP65k',
  });
  const center = useSelector(({ center }) => center);
  const [map, setMap] = useState(null);
  const dispatch = useDispatch();

  const eventHandler = () => {
    const LatLng = map?.getCenter().toJSON() || center;
    if (LatLng.lat !== center.lat || LatLng.lng !== center.lng) {
      dispatch(centerChange(LatLng));
    }
  };

  // Tenemos doble debounce. Con este logramos evitar que se emitan demasiadas
  // acciones a redux. Con el timer en la epica evitamos saturar las peticiones
  // al geocoder. Se podria eliminar cualquiera de los dos.
  const debouncedCenterSet = AwesomeDebouncePromise(eventHandler, 100);

  const onLoad = useCallback((map) => setMap(map), []);

  const renderMap = () => {
    let google = window.google;
    const icon = {
      url: pointer,
      scaledSize: new google.maps.Size(128, 128),
      anchor: new google.maps.Point(64, 64),
    };

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onClick={(event) => {
          dispatch(centerChange(event.latLng.toJSON()));
        }}
        onCenterChanged={() => debouncedCenterSet()}
        onLoad={onLoad}
      >
        <Marker position={center} icon={icon} clickable={false} />
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap() : <></>;
};

export default memo(Map);
