import './Body.scss';
import Page from '../page/Page';
import { useSelector } from 'react-redux';
import Summary from '../summary/Summary';
import Type from '../type/Type';
import Contact from '../contact/Contact';
import Options from '../options/Options';
import Navigator from '../navigator/Navigator';
import Footer from '../footer/Footer';
import Message from '../message/Message';
import { useJsApiLoader } from '@react-google-maps/api';

const Body = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDczz8zwETI6vcJDMk5g2vELbhMvviP65k',
  });

  const page = useSelector(({ page }) => page);
  return isLoaded ? (
    <main>
      <Page />
      <vertical-layout>
        {page === 1 ? <Type /> : null}
        {page === 2 ? <Options /> : null}
        {page === 3 ? <Contact /> : null}
        {page === 4 ? <Summary /> : null}
        <Navigator />
        <Footer />
      </vertical-layout>
      <Message />
    </main>
  ) : (
    <></>
  );
};

export default Body;
