export const INIT = 'INIT';

export const ADDRESS_SET = 'ADDRESS_SET';
export const addressSet = (payload) => ({
  type: ADDRESS_SET,
  payload,
});

export const CAPTCHA_SET = 'CAPTCHA_SET';
export const setCaptcha = (payload) => ({
  type: CAPTCHA_SET,
  payload,
});

export const CENTER_CHANGE = 'CENTER_CHANGE';
export const centerChange = (payload) => ({
  type: CENTER_CHANGE,
  payload,
});

export const CONTACT_SET = 'CONTACT_SET';
export const contactSet = (payload) => ({
  type: CONTACT_SET,
  payload,
});
export const DATA_SET = 'DATA_SET';
export const dataSet = (payload) => ({
  type: DATA_SET,
  payload,
});
export const EMAIL_SET = 'EMAIL_SET';
export const emailSet = (payload) => ({
  type: EMAIL_SET,
  payload,
});
export const FILE_SET = 'FILE_SET';
export const fileSet = (payload) => ({
  type: FILE_SET,
  payload,
});

export const LANGUAGE_SET = 'LANGUAGE_SET';
export const languageSet = (payload) => ({
  type: LANGUAGE_SET,
  payload,
});

export const MAP_SET = 'MAP_SET';
export const mapSet = (payload) => ({
  type: MAP_SET,
  payload,
});

export const OBSERVATIONS_SET = 'OBSERVATIONS_SET';
export const observationsSet = (payload) => ({
  type: OBSERVATIONS_SET,
  payload,
});

export const OPTION_SET = 'OPTION_SET';
export const optionSet = (payload) => ({
  type: OPTION_SET,
  payload,
});

export const PAGE_SET = 'PAGE_SET';
export const pageSet = (payload) => ({
  type: PAGE_SET,
  payload,
});

export const PAGE_NEXT = 'PAGE_NEXT';
export const pageNext = (payload) => ({
  type: PAGE_NEXT,
  payload,
});

export const PAGE_PREVIOUS = 'PAGE_PREVIOUS';
export const pagePrevious = (payload) => ({
  type: PAGE_PREVIOUS,
  payload,
});

export const PHONE_SET = 'PHONE_SET';
export const phoneSet = (payload) => ({
  type: PHONE_SET,
  payload,
});

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const showMessage = (payload) => ({
  type: SHOW_MESSAGE,
  payload,
});

export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const removeMessage = (payload) => ({
  type: REMOVE_MESSAGE,
  payload,
});

export const SUBMIT = 'SUBMIT';
export const submit = (payload) => ({
  type: SUBMIT,
  payload,
});

export const TYPE_SET = 'TYPE_SET';
export const typeSet = (payload) => ({
  type: TYPE_SET,
  payload,
});
