import './Contact.scss';
import { useSelector, useDispatch } from 'react-redux';
import Map from '../map/Map';
import {
  addressSet,
  contactSet,
  emailSet,
  fileSet,
  observationsSet,
  phoneSet,
} from '../../redux/actions';

const Contact = () => {
  const dispatch = useDispatch();
  const locale = useSelector(({ locale }) => locale);
  const key = useSelector(({ type }) => type);
  const types = useSelector(({ types }) => types);
  const type = types.find((type) => type.key === key);
  const address = useSelector(({ address }) => address);
  const contact = useSelector(({ contact }) => contact);
  const email = useSelector(({ email }) => email.value);
  const filename = useSelector(({ filename }) => filename);
  const phone = useSelector(({ phone }) => phone.value);
  const observations = useSelector(({ observations }) => observations);
  const id = useSelector(({ option }) => option);
  const options = useSelector(({ options }) => options);
  const option = options.find((option) => option.id === id);

  return (
    <section className='contact'>
      <section-header>
        <header-left>
          <div className='title'>{locale.contact}:</div>
          <div className='subtitle'>{type.text}:</div>
        </header-left>
        <header-right>
          <button-icon class={`i${type.key}`}></button-icon>
        </header-right>
      </section-header>
      <form>
        <form-group class='grow'>
          <label htmlFor='address'>{locale.address}:</label>
          <input
            type='text'
            name='address'
            placeholder={locale.addressPlaceholder}
            required
            value={address}
            disabled
            onChange={({ target }) => dispatch(addressSet(target.value))}
          />
          <google-map class='grow'>
            <Map />
          </google-map>
        </form-group>
        <form-group class='small'>
          <label htmlFor='contact'>*{locale.name}:</label>
          <input
            type='text'
            name='contact'
            placeholder={locale.namePlaceholder}
            value={contact}
            required
            onChange={({ target }) => dispatch(contactSet(target.value))}
          />
        </form-group>
        <form-group class='small'>
          <label htmlFor='phone'>*{locale.phone}:</label>
          <input
            type='tel'
            name='phone'
            minLength='9'
            placeholder={locale.phonePlaceholder}
            value={phone}
            required
            onChange={({ target }) =>
              dispatch(
                phoneSet({ value: target.value, valid: target.validity.valid })
              )
            }
          />
        </form-group>
        <form-group>
          <label htmlFor='email'>*{locale.email}:</label>
          <input
            type='email'
            name='email'
            placeholder={locale.emailPlaceholder}
            value={email}
            required
            onChange={({ target }) =>
              dispatch(
                emailSet({ value: target.value, valid: target.validity.valid })
              )
            }
          />
        </form-group>
        <form-group>
          <label htmlFor='observations'>*{locale.observations}:</label>
          <textarea
            rows='3'
            name='observations'
            placeholder={`${locale.observationsPlaceholder} ${option.details}`}
            value={observations}
            onChange={({ target }) => dispatch(observationsSet(target.value))}
          />
        </form-group>
        <form-group>
          <label htmlFor='file'>
            {locale.photo}: {filename}
          </label>
          <input
            type='file'
            name='file'
            onChange={({ target }) => {
              dispatch(fileSet({ files: target.files, value: target.value }));
            }}
          />
        </form-group>
      </form>
    </section>
  );
};

export default Contact;
