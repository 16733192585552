import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Header from './components/header/Header';
import Body from './components/body/Body';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducer from './redux/reducers';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './redux/epics';
import { composeWithDevTools } from 'redux-devtools-extension';

const epicMiddleware = createEpicMiddleware();

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);

epicMiddleware.run(rootEpic);

store.dispatch({ type: 'INIT' });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Header />
      <Body />
    </Provider>
  </React.StrictMode>,
  document.getElementsByTagName('app-root')[0]
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
