import './Page.scss';
import { pageSet } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const Page = () => {
  const page = useSelector(({ page }) => page);
  const dispatch = useDispatch();

  const buttons = [1, 2, 3, 4].map((number) => (
    <button
      className={`${page === number ? 'selected' : ''}`}
      key={number}
      onClick={() => dispatch(pageSet(number))}
    >
      <span>{number}</span>
    </button>
  ));

  return <page-selector>{buttons}</page-selector>;
};

export default Page;
