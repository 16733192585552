import './Summary.scss';
import { setCaptcha } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

const Summary = () => {
  const locale = useSelector(({ locale }) => locale);
  const key = useSelector(({ type }) => type);
  const types = useSelector(({ types }) => types);
  const type = types.find((type) => type.key === key);
  const address = useSelector(({ address }) => address);
  const contact = useSelector(({ contact }) => contact);
  const email = useSelector(({ email }) => email.value);
  const filename = useSelector(({ filename }) => filename);
  const phone = useSelector(({ phone }) => phone.value);
  const observations = useSelector(({ observations }) => observations);

  const dispatch = useDispatch();

  return (
    <section className='summary'>
      <section-header>
        <header-left>
          <div className='title'>{locale.summary}:</div>
          <div className='subtitle'>{type.text}:</div>
        </header-left>
        <header-right>
          <button-icon class={`i${type.key}`}></button-icon>
        </header-right>
      </section-header>
      <summary-content>
        <summary-group>
          <summary-label>{locale.address}:</summary-label>
          <summary-value>{address}</summary-value>
        </summary-group>
        <summary-group class='small'>
          <summary-label>{locale.name}:</summary-label>
          <summary-value>{contact}</summary-value>
        </summary-group>
        <summary-group class='small'>
          <summary-label>{locale.phone}:</summary-label>
          <summary-value>{phone}</summary-value>
        </summary-group>
        <summary-group>
          <summary-label>{locale.email}:</summary-label>
          <summary-value>{email}</summary-value>
        </summary-group>
        <summary-group>
          <summary-label>{locale.observations}:</summary-label>
          <summary-value>{observations}</summary-value>
        </summary-group>
        <summary-group>
          <summary-label>{locale.photo}:</summary-label>
          <summary-value>{filename}</summary-value>
        </summary-group>
        <summary-group>
          <ReCAPTCHA
            sitekey='6Leh_RQTAAAAALNMtmk2YUwDA_jaGGt9s3YbvsDi'
            onChange={(r) => {
              dispatch(setCaptcha(r));
            }}
          />
        </summary-group>
      </summary-content>
    </section>
  );
};

export default Summary;
