import { combineEpics, ofType } from 'redux-observable';
import {
  timer,
  from,
  map,
  exhaustMap,
  mergeMap,
  switchMap,
  withLatestFrom,
  Observable,
  filter,
  iif,
  of,
  catchError,
} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  addressSet,
  dataSet,
  showMessage,
  INIT,
  centerChange,
  CENTER_CHANGE,
  SUBMIT,
  SHOW_MESSAGE,
  PAGE_SET,
  PAGE_NEXT,
  removeMessage,
  LANGUAGE_SET,
} from './actions';

const urlGet =
  document.location.hostname === 'localhost'
    ? '/data.json'
    : '/apprest/login-ios';

const urlPost = '/apprest/request-ios';

const initialLoadEpic = (action$, state$) =>
  action$.pipe(
    filter(({ type }) => [INIT, LANGUAGE_SET].includes(type)),
    withLatestFrom(state$),
    mergeMap(([action, { language }]) =>
      ajax
        .getJSON(urlGet, { 'Accept-Language': language })
        .pipe(map((response) => dataSet(response)))
    )
  );

const userLocationEpic = (action$, state$) =>
  action$.pipe(
    ofType(INIT),
    mergeMap((action) =>
      from(
        new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition((res) =>
            resolve(
              centerChange({
                lat: res.coords.latitude,
                lng: res.coords.longitude,
              })
            )
          );
        })
      )
    )
  );

const submitEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT),
    withLatestFrom(state$),
    exhaustMap(([action, state]) =>
      iif(
        () => state.captcha === '',
        of(
          showMessage({
            id: 'captcha',
            text: state.locale.error4,
            type: 'error',
          })
        ),
        new Observable((s) => {
          fetch(state.file).then((r) =>
            r.blob().then((file) => {
              const body = new FormData();
              body.append('phone', state.phone.value);
              body.append('email', state.email.value);
              body.append('contact', state.contact);
              body.append('address', state.address);
              body.append('observations', state.observations);
              body.append('file', file);
              body.append('filename', state.filename);
              body.append('language', state.language);
              body.append('lat', state.center.lat);
              body.append('lng', state.center.lng);
              body.append('type', state.type);
              body.append('ambit', state.ambit);
              body.append('option', state.option);
              body.append('g-recaptcha-response', state.captcha);

              fetch(urlPost, {
                method: 'POST',
                body,
              })
                .then((res) => res.json())
                .then((res) => {
                  s.next({ type: 'RESET' });
                  s.next(
                    showMessage({
                      id: res.altainc,
                      text: `${state.locale.newInc} ${res.altainc}.
                      ${state.locale.confirmation}`,
                      type: 'ok',
                    })
                  );
                  s.complete();
                });
            })
          );
        })
      )
    )
  );

const geocodeEpic = (action$, state$) =>
  action$.pipe(
    ofType(CENTER_CHANGE),
    withLatestFrom(state$),
    switchMap(([{ payload }, { locale }]) =>
      timer(300).pipe(
        mergeMap(() => {
          const Geocoder = new window.google.maps.Geocoder();
          return from(Geocoder.geocode({ location: payload })).pipe(
            mergeMap((data) =>
              of(addressSet(data.results[0]?.formatted_address || ''))
            ),
            catchError((error) =>
              of(
                showMessage({ id: error, text: locale.errorGeo, type: 'error' })
              )
            )
          );
        })
      )
    )
  );

export const autoHideNotificationEpic = (action$, state$) =>
  action$.pipe(
    filter(({ type }) => [SHOW_MESSAGE, PAGE_SET, PAGE_NEXT].includes(type)),
    withLatestFrom(state$),
    mergeMap(([action, { messages }]) =>
      iif(
        () => messages.length > 0,
        timer(5000).pipe(map(() => removeMessage({ id: messages.at(-1).id }))),
        []
      )
    )
  );

export const rootEpic = combineEpics(
  initialLoadEpic,
  userLocationEpic,
  submitEpic,
  geocodeEpic,
  autoHideNotificationEpic
);
