import './Navigator.scss';
import { pageNext, pagePrevious, submit } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const Navigator = () => {
  const locale = useSelector(({ locale }) => locale);
  const page = useSelector(({ page }) => page);
  const dispatch = useDispatch();

  return (
    <navigator-menu>
      {page > 1 ? (
        <button className='left' onClick={() => dispatch(pagePrevious())}>
          {locale.previous}
        </button>
      ) : (
        <div></div>
      )}
      {page < 4 ? (
        <button className='right' onClick={() => dispatch(pageNext())}>
          {locale.next}
        </button>
      ) : null}
      {page === 4 ? (
        <button className='right' onClick={() => dispatch(submit())}>
          {locale.submit}
        </button>
      ) : null}
    </navigator-menu>
  );
};

export default Navigator;
