import React from 'react';
import './Options.scss';
import { optionSet } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const Options = () => {
  const locale = useSelector(({ locale }) => locale);
  const id = useSelector(({ option }) => option);
  const options = useSelector(({ options }) => options);
  const option = options.find((option) => option.id === id);
  const key = useSelector(({ type }) => type);
  const types = useSelector(({ types }) => types);
  const type = types.find((type) => type.key === key);
  const dispatch = useDispatch();

  const URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  const splitTxt = (txt) =>
    txt.split(' ').map((part) =>
      URL_REGEX.test(part) ? (
        <React.Fragment key={part + 2}>
          <a key={part} href={part} target='_blank' rel='noreferrer'>
            {part}
          </a>
          <span key={part + 2}>&nbsp;</span>
        </React.Fragment>
      ) : (
        part + ' '
      )
    );

  const renderText = (txt) =>
    txt.split('\n').map((str) => <p>{splitTxt(str)}</p>);

  const text = option && option.details ? renderText(option.details) : '';

  const buttons = options.map((o, index) => (
    <button
      className={`${option === o ? 'selected' : ''}`}
      key={index}
      onClick={() => dispatch(optionSet(o.id))}
    >
      <button-icon></button-icon>
      <button-text>{o.text}</button-text>
    </button>
  ));

  return (
    <section className='option'>
      <section-header>
        <header-left>
          <div className='title'>{locale.options}:</div>
          <div className='subtitle'>{type.text}:</div>
        </header-left>
        <header-right>
          <button-icon class={`i${type.key}`}></button-icon>
        </header-right>
      </section-header>
      <options-content>{buttons}</options-content>
      {option ? <option-description>{text}</option-description> : null}
    </section>
  );
};

export default Options;
