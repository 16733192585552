import * as actions from './actions';

const initialState = {
  address: '',
  captcha: '',
  contact: '',
  email: {
    value: '',
    valid: false,
  },
  file: '',
  filename: '',
  language: 'ca',
  languages: ['es', 'ca', 'en'],
  locale: {},
  locales: {
    ca: {
      confirmation: "S'ha enviat un missatge de confirmació al correu indicat.",
      townhall: 'Ajuntament de Rubí',
      title: 'Telèfon verd en línia',
      subtitle: `Servei telemàtic del Telèfon Verd. Petició de serveis mediambientals relacionats amb la prevenció i la gestió de residus`,
      previous: 'Anterior',
      next: 'Següent',
      submit: 'Enviar',
      request: 'Seleccioneu Petició',
      options: 'Especifiqueu opcions',
      contact: 'Indiqueu contacte',
      address: 'Adreça',
      addressPlaceholder: 'Carrer / plaça / avinguda..., número',
      name: 'Contacte',
      namePlaceholder: 'Nom de contacte',
      newInc: `S'ha creat la incidència número: `,
      phone: 'Telèfon',
      phonePlaceholder: 'Telèfon fix o mòbil',
      email: 'Email',
      emailPlaceholder: 'email@correu.electronic',
      observations: 'Observacions',
      observationsPlaceholder: 'Comentaris o documentació addicionals...',
      photo: 'Foto',
      error1: 'Seleccioneu un tipus de petició per continuar',
      error2: `Seleccioneu l'opció adequada`,
      error3: 'Completeu els camps obligatoris',
      error4: 'Completeu els camps obligatoris',
      errorGeo: 'Error en geoposicionar al mapa',
      summary: 'Resum petició',
    },
    en: {
      confirmation:
        'A confirmation message has been sent to the indicated email.',
      townhall: `Rubí's Townhall`,
      title: 'Online green phone',
      subtitle: `Green Phone telematic service. Enviromental service request related to prevention and waste management`,
      previous: 'Previous',
      next: 'Continue',
      submit: 'Submit',
      request: 'Request',
      options: 'Options',
      contact: 'Contact',
      address: 'Address',
      addressPlaceholder: 'Street / square / av...., number',
      name: 'Contact',
      namePlaceholder: 'Contact name',
      newInc: `New incident created with number: `,
      phone: 'Phone',
      phonePlaceholder: 'Mobile or line number',
      email: 'Email',
      emailPlaceholder: 'email@electronic.mail',
      observations: 'Comments',
      observationsPlaceholder: 'Comments or aditional attachements',
      photo: 'Photo',
      error1: 'Please select a request type to continue',
      error2: 'Select the appropriate option',
      error3: 'Fill in the required fields',
      error4: 'Fill in the required fields',
      errorGeo: 'Error map geoposition',
      summary: 'Request summary',
    },
    es: {
      confirmation:
        'Se ha enviado un mensaje de confirmación al correo indicado.',
      townhall: 'Ayuntamiento de Rubí',
      title: 'Teléfono verde en línea',
      subtitle: `Servicio telemático del Teléfono Verde. Petición de servicios medioambientales relacionados con la prevención y la gestión de residuos`,
      previous: 'Anterior',
      next: 'Siguiente',
      submit: 'Enviar',
      request: 'Seleccionar Petición',
      options: 'Especifique opciones',
      contact: 'Indique contacto',
      address: 'Dirección',
      addressPlaceholder: 'Calle / plaza / avenida..., número',
      name: 'Contacto',
      namePlaceholder: 'Nombre de contacto',
      newInc: `Se ha creado la incidencia número`,
      phone: 'Teléfono',
      phonePlaceholder: 'Teléfono fijo o móvil',
      email: 'Email',
      emailPlaceholder: 'email@correo.electronico',
      observations: 'Observaciones',
      observationsPlaceholder: 'Comentarios o documentación adicional...',
      photo: 'Foto',
      error1: 'Por favor, seleccione un tipo de petición para continuar',
      error2: 'Seleccione la opción adecuada',
      error3: 'Complete los campos obligatorios',
      error4: 'Complete los campos obligatorios',
      errorGeo: 'Error al geoposicionar en el mapa',
      summary: 'Resumen petición',
    },
  },
  center: { lat: 41.498669485482964, lng: 2.034962629422866 },
  observations: '',
  option: null,
  options: [],
  phone: {
    value: '',
    valid: false,
  },
  type: null,
  types: [],
  page: 1,
  messages: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'INIT':
      return { ...state, locale: state.locales[state.language] };
    case 'RESET':
      return {
        ...initialState,
        data: state.data,
        types: state.types,
        messages: state.messages,
        language: state.language,
        locale: state.locales[state.language],
      };
    case actions.ADDRESS_SET:
      return { ...state, address: payload };

    case actions.DATA_SET: {
      const data = payload.calificacion;
      return {
        ...state,
        data,
        types: Object.keys(data).map((key) => ({
          icon: data[key].icono,
          text: data[key].denominacion,
          details: data[key].observaciones,
          key,
        })),
        options: state.type
          ? data[state.type].informaciones[state.ambit].elementos.map(
              (option) => ({
                id: option[0],
                text: option[1],
                icon: option[2],
                details: option[3],
              })
            )
          : [],
      };
    }

    case actions.CAPTCHA_SET:
      return { ...state, captcha: payload };

    case actions.CENTER_CHANGE:
      return { ...state, center: payload };

    case actions.CONTACT_SET:
      return { ...state, contact: payload };

    case actions.EMAIL_SET:
      return { ...state, email: payload };

    case actions.FILE_SET:
      return {
        ...state,
        file: URL.createObjectURL(payload.files[0]),
        filename: payload.files[0].name,
      };

    case actions.LANGUAGE_SET:
      return { ...state, language: payload, locale: state.locales[payload] };

    case actions.MAP_SET:
      return { ...state, map: payload };

    case actions.OPTION_SET:
      return { ...state, option: payload };

    case actions.PAGE_SET: {
      const pageError =
        (payload > 1 && !state.type) ||
        (payload > 2 && !state.option) ||
        (payload > 3 &&
          (!state.address ||
            !state.contact ||
            !state.phone.valid ||
            !state.email.valid ||
            !state.observations));

      return {
        ...state,
        messages: [
          ...state.messages,
          ...(pageError
            ? [
                {
                  id: `page${state.page}${state.messages.length}`,
                  text: state.locale[`error${state.page}`],
                  type: 'error',
                },
              ]
            : []),
        ],
        page: pageError ? state.page : payload,
      };
    }
    case actions.PAGE_NEXT: {
      const pageError =
        (state.page === 1 && !state.type) ||
        (state.page === 2 && !state.option) ||
        (state.page === 3 &&
          (!state.address ||
            !state.contact ||
            !state.phone.valid ||
            !state.email.valid ||
            !state.observations));

      return {
        ...state,
        messages: [
          ...state.messages,
          ...(pageError
            ? [
                {
                  id: `page${state.page}${state.messages.length}`,
                  text: state.locale[`error${state.page}`],
                  type: 'error',
                },
              ]
            : []),
        ],
        page: pageError ? state.page : state.page + 1,
      };
    }
    case actions.PAGE_PREVIOUS:
      return { ...state, page: state.page - 1 };

    case actions.PHONE_SET:
      return { ...state, phone: payload };

    case actions.OBSERVATIONS_SET:
      return { ...state, observations: payload };

    case actions.TYPE_SET: {
      const ambit = Object.keys(state.data[payload].informaciones)[0];
      return {
        ...state,
        type: payload,
        ambit,
        option: null,
        options: state.data[payload].informaciones[ambit].elementos.map(
          (option) => ({
            id: option[0],
            text: option[1],
            icon: option[2],
            details: option[3],
          })
        ),
      };
    }

    case actions.SHOW_MESSAGE:
      return { ...state, messages: [...state.messages, payload] };

    case actions.REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((m) => m.id !== payload.id),
      };

    default:
      return state;
  }
};

export default reducer;
