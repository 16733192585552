import './Language.scss';
import { languageSet } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const Language = () => {
  const language = useSelector(({ language }) => language);
  const languages = useSelector(({ languages }) => languages);
  const dispatch = useDispatch();
  const buttons = languages.map((lan, index) => (
    <button
      className={`${language === lan ? 'selected' : ''}`}
      key={index}
      onClick={() => dispatch(languageSet(lan))}
    >
      {lan}
    </button>
  ));

  return <language-selector>{buttons}</language-selector>;
};

export default Language;
