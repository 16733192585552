import './Message.scss';
import { useSelector } from 'react-redux';

const getIcon = (type) =>
  type === 'error'
    ? 'fa-solid fa-exclamation'
    : type === 'ok'
    ? 'fa fa-check'
    : '';

const Message = () => {
  const messages = useSelector(({ messages }) => messages);

  return (
    <messages-container>
      {messages.map((message, i) => (
        <message-container key={i} message-type={message.type}>
          <message-icon>
            <i className={`${getIcon(message.type)}`}></i>
          </message-icon>
          <message-body>
            <p>{message.text}</p>
          </message-body>
        </message-container>
      ))}
    </messages-container>
  );
};

export default Message;
