import './Header.scss';
import Language from '../language/Language';
import { useSelector } from 'react-redux';

const Header = () => {
  const locale = useSelector(({ locale }) => locale);
  return (
    <header>
      <div>
        <a href='https://rubi.cat/residus'>
          <div className='pretitle'>{locale.pretitle}</div>
        </a>
        <div className='title'>{locale.title}</div>
        <div className='subtitle'>{locale.subtitle}</div>
      </div>
      <Language />
    </header>
  );
};

export default Header;
