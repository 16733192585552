import React from 'react';
import './Type.scss';
import { typeSet } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

const Type = ({ setType }) => {
  const locale = useSelector(({ locale }) => locale);
  const key = useSelector(({ type }) => type);
  const types = useSelector(({ types }) => types);
  const type = types.find((type) => type.key === key);
  const dispatch = useDispatch();

  const URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  const splitTxt = (txt) =>
    txt.split(' ').map((part) =>
    URL_REGEX.test(part) ? (
      <React.Fragment key={part + 2}>
        <a key={part} href={part} target='_blank' rel='noreferrer'>
          {part}
        </a>
        <span key={part + 2}>&nbsp;</span>
      </React.Fragment>
    ) : (
      part + ' '
    )
  );
  const renderText = (txt) =>
    txt.split('\n').map((str) => <p>{splitTxt(str)}</p>)


  const text = type && type.details ? renderText(type.details) : '';

  const buttons = types.map((t, index) => (
    <button
      className={`${type === t ? 'selected' : ''}`}
      key={index}
      onClick={() => dispatch(typeSet(t.key))}
    >
      <button-icon class={`i${t.key}`}></button-icon>
      <button-text>{t.text}</button-text>
    </button>
  ));

  return (
    <section className='type'>
      <div className='title'>{locale.request}:</div>
      <types-content>{buttons}</types-content>
      <type-description>{text}</type-description>
    </section>
  );
};

export default Type;
